import {Component, Input} from "@angular/core";
import {StoriesLandingPage, StoriesLandingPageSubject} from "src/app/interfaces/stories/stories-landing-page";

@Component({
  selector: "app-stories-landing-subjects",
  templateUrl: "./stories-landing-subjects.component.html",
  styleUrls: ["./stories-landing-subjects.component.scss"],
})
export class StoriesLandingSubjectsComponent {
  @Input() meta!: StoriesLandingPage;
  @Input() subjects!: StoriesLandingPageSubject[];
}
