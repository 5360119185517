import {Component, OnInit} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {CarouselSlide} from "src/app/interfaces/carousel-slide";
import {
  StoriesLandingPage,
  StoriesLandingPageData,
  StoriesLandingPageRegulation,
  StoriesLandingPageSubject,
} from "src/app/interfaces/stories/stories-landing-page";

@Component({
  selector: "app-stories-landing-page",
  templateUrl: "./stories-landing-page.component.html",
  styleUrls: ["./stories-landing-page.component.scss"],
})
export class StoriesLandingPageComponent implements OnInit {
  meta!: StoriesLandingPage;

  carouselSlides!: CarouselSlide[];
  regulations!: StoriesLandingPageRegulation[];
  subjects!: StoriesLandingPageSubject[];

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    const {meta, ...page} = this.route.snapshot.data["page"] as StoriesLandingPageData;
    this.meta = meta;
    this.carouselSlides = page.data.carousel;
    this.regulations = page.data.regulations;
    this.subjects = page.data.subjects;
  }
}
