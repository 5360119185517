import {Component, Input} from "@angular/core";
import {CarouselSlide} from "src/app/interfaces/carousel-slide";

@Component({
  selector: "app-stories-landing-carousel",
  templateUrl: "./stories-landing-carousel.component.html",
  styleUrls: ["./stories-landing-carousel.component.scss"],
})
export class StoriesLandingCarouselComponent {
  @Input() slides!: CarouselSlide[];
}
