<div id="stories-about" class="bg-opacity-10 py-5 my-5">
  <div class="container">
    <div class="row">
      <div
        class="col-lg-7 col-12 d-flex d-lg-block flex-column justify-content-center align-items-center text-center text-lg-start"
      >
        <h6 class="fs-1 fw-bold my-4">{{ meta.aboutSectionTitle }}</h6>

        <div
          class="d-flex flex-column align-items-center gap-5 text-start my-4 lh-lg mb-reset"
          [innerHTML]="meta.aboutSectionContent"
        ></div>
      </div>
      <div class="col-lg-5 d-none d-lg-flex p-0 m-0 justify-content-end align-items-center">
        <img
          [src]="meta.aboutSectionImage | imageLink"
          class="img-fluid cover"
          style="min-height: 200px; max-height: 300px"
          [alt]="meta.aboutSectionTitle"
        />
      </div>
    </div>
  </div>
</div>
