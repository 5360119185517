import {Component, Input} from "@angular/core";
import {StoriesLandingPage, StoriesLandingPageRegulation} from "src/app/interfaces/stories/stories-landing-page";

@Component({
  selector: "app-stories-landing-regulations",
  templateUrl: "./stories-landing-regulations.component.html",
  styleUrls: ["./stories-landing-regulations.component.scss"],
})
export class StoriesLandingRegulationsComponent {
  @Input() meta!: StoriesLandingPage;
  @Input() regulations!: StoriesLandingPageRegulation[];
}
